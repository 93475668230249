const MEGA_MENU_ACTIVE_CLASS = 'mega-menu--active'
const HEADER_MEGA_ACTIVE_CLASS = 'header--mega-active'
const BUFFER_TIME = 300
let buffer = BUFFER_TIME
let bufferTimer = null
let activeTrigger = null
let leaveTimer = null

function setup(trigger) {

    const header = document.querySelector('header')
    const id = trigger.dataset.megaTrigger
    const menu = document.querySelector(`[data-mega="${id}"]`)
    const menus = Array.from(document.querySelectorAll(`[data-mega]`))

    trigger.addEventListener('mouseenter', () => {
        clearTimeout(leaveTimer)
        leaveTimer = null

        if (activeTrigger === null) {
            activeTrigger = id
            buffer = 0
        }

        bufferTimer = setTimeout(() => {
            activeTrigger = id

            menus.forEach(m => {
                if (m.dataset != menu.dataset) {
                    m.classList.remove(MEGA_MENU_ACTIVE_CLASS)
                }
            })

            header.classList.add(HEADER_MEGA_ACTIVE_CLASS)
            menu.classList.add(MEGA_MENU_ACTIVE_CLASS)

            // Reset buffer between each hover
            buffer = BUFFER_TIME
            clearTimeout(bufferTimer)
            bufferTimer = null

        }, buffer)
    })

    trigger.addEventListener('mouseleave', () => {

        // Wait for the buffer time if the active Trigger is the same as the ID then turn menu off
        leaveTimer = setTimeout(() => {
            if (activeTrigger === id) {
                header.classList.remove(HEADER_MEGA_ACTIVE_CLASS)
                leaveTimer = null
                activeTrigger = null
            }
        }, bufferTimer + 1)
    })

    menu.addEventListener('mouseenter', () => {

        if (activeTrigger === id) {
            clearTimeout(bufferTimer)
            clearTimeout(leaveTimer)
            bufferTimer = null
            leaveTimer = null
        }
    })

    menu.addEventListener('mouseleave', () => {
        clearTimeout(bufferTimer)
        bufferTimer = null

        if (activeTrigger === id) {
            menu.classList.remove(MEGA_MENU_ACTIVE_CLASS)

            const found = menus.find(menu => {
                return menu.classList.contains(MEGA_MENU_ACTIVE_CLASS)
            })

            if (!found) {
                header.classList.remove(HEADER_MEGA_ACTIVE_CLASS)
                activeTrigger = null
            }
        }
    })
}


function init() {

    const triggers = Array.from(document.querySelectorAll('[data-mega-trigger]'))

    triggers.forEach(setup)
}

init()
