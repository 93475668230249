import { tns } from "tiny-slider";

function next(sliders) {
    sliders.forEach(slider => {
        slider.goTo('next')
    })
}

function prev(sliders) {
    sliders.forEach(slider => {
        slider.goTo('prev')
    })
}

function setup(slider) {

    const imageWrapperElement = slider.querySelector('.basic-slider__image-slides')

    if (!imageWrapperElement) return

    if (imageWrapperElement.children.length < 1) return

    const contentWrapperElement = slider.querySelector('.basic-slider__content-slides')

    const nextButton = slider.querySelector('.basic-slider__controls .next')
    const prevButton = slider.querySelector('.basic-slider__controls .prev')

    const stepper = slider.querySelector('.basic-slider__position-indicator-stepper')
    const realSlideCount = Array.from(slider.querySelectorAll('.basic-slider__position-indicator-steps div')).length
    const step = slider.querySelector('.basic-slider__position-indicator-steps div')

    let ready = 0

    const elements = [
        imageWrapperElement,
        contentWrapperElement
    ]

    function setStepperSize() {
        stepper.style.width = `${step.offsetWidth}px`
    }

    function bindStepperPosition(slider) {

        slider.events.on('indexChanged', () => {
            let index = slider.getInfo().displayIndex
            const actualCount = slider.getInfo().slideCount / 2

            // There might be a but here because of how sliders with less than 3 elements are set up

            if (index > actualCount && realSlideCount <= 3) {
                index = index - actualCount
            }

            const value = 100 * (index - 1)

            stepper.style.translate = `${value}% 0`
        })
    }

    function bindSlidersToMaster(master, sliders) {
        master.events.on('indexChanged', () => {
            const { displayIndex } = master.getInfo()
            sliders.forEach(slider => {
                slider.goTo(displayIndex - 1)
            })
        })
    }

    function onInit(slider) {
        ready++
        if (ready === elements.length) {
            setStepperSize()
            console.log('All sliders initialized')
        }
    }

    const options = elements.map((el, i) => {
        return {
            container: el,
            items: 1,
            gutter: 24,
            controls: false,
            nav: false,
            mouseDrag: (i === 0),
            onInit,
            lazyload: true,
            responsive: {
                768: {
                    items: 3
                }
            }
        }
    })

    const sliders = options.map(config => tns(config))

    const master = sliders.shift()

    bindStepperPosition(master)
    bindSlidersToMaster(master, sliders)

    nextButton.addEventListener('click', () => {
        next([master])
    })

    prevButton.addEventListener('click', () => {
        prev([master])
    })

    return sliders
}

function observe(slider) {

    let observed = false

    function intersectionCallback(entries) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Perform the desired action when the element is within 100px of the viewport
                setup(slider)
                observed = true
            }
        });
    }

    const observer = new IntersectionObserver(intersectionCallback, {
        root: null, // Use the viewport as the root
        rootMargin: '100px 0px 100px 0px', // Trigger the callback when within 100px of top or bottom
        threshold: 0 // Trigger as soon as any part of the element is visible
    });

    // Start observing the target element
    observer.observe(slider);
}

function init() {

    const sliders = Array.from(document.querySelectorAll('.basic-slider'))

    if (!sliders.length) return;

    sliders.forEach(observe)
}

init();
